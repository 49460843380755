.currency-switcher {
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  &__toggle {
    background-color: color('white');
    border: 1px solid color('ipmi-blue-40');
    display: block;
    padding: $spacing-m $spacing-m;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: color('ipmi-blue-40');
    }

    &:focus {
      outline: none;
    }
  }

  &__active {
    background-color: color('digital-blue-dark');
    border: 1px solid color('digital-blue-dark');
    color: color('white');

    &:hover {
      background-color: color('digital-blue-dark');
    }
  }
}
