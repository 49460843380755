//
// Collapse component
// .collapse hides content
// .collapsing is applied during transitions
// .collapse.show shows content
//

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease 0.05s;
}
