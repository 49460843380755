.pagination {
  display: inline-flex;
  padding-left: 0;
  list-style: none;

  &__container {
    @include make-container();
    @include make-container-max-widths();

    text-align: center;

    @include media-breakpoint-down(xs) {
      overflow-x: auto;
    }
  }

  &__link {
    background-color: color('white');
    color: color('digital-blue');
    display: block;
    line-height: 1.25;
    padding: $spacing-m;
    position: relative;

    &--active {
      color: color('white');
      background-color: color('digital-blue');
      border-color: color('digital-blue');
      border-radius: $btn-border-radius;
      z-index: 1;

      &:hover {
        color: color('white');
      }
    }

    &--nav {
      color: color('white');
      background-color: color('digital-blue');
      border-color: color('digital-blue');
      border-radius: $btn-border-radius;
      padding: $spacing-m $spacing-ml;
      z-index: 1;

      &:hover {
        color: color('white');
      }
    }

    &--disabled {
      color: color('gray-05');
      pointer-events: none;
      cursor: auto;
      background-color: color('white');
      border-color: color('gray-05');
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }
}
