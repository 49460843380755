//
// Progress
//

.progress {
  background-color: $progress-bg-color;
  border-radius: $radius-s;
  display: flex;
  font-size: 0.75rem;
  height: $spacing-m;
  overflow: hidden;

  &__bar {
    background-color: $progress-bar-bg-color;
    color: $progress-bar-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    transition: $progress-bar-transition;
    white-space: nowrap;
  }
}
