a {
  color: $link-color;
  cursor: pointer;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.download-link,
.external-link,
.secure-link-requiring-login-link {
  @extend %link-underline;
}

.link {
  &__action {
    font-weight: $font-weight-regular !important;
    margin-right: $spacing-s;

    + svg {
     margin-bottom: 2px;
    }
  }

  &__back {
    color: $link-color !important;
    font-weight: $font-weight-semibold;
    padding-bottom: $spacing-xs;

    &:hover {
      @extend %link-underline;
    }
  }
}
