//
// Footer
//

//
// Max widths of footer body container.
//

$footer-max-widths: (
  lg: 960px,
  xl: 1180px
);


.footer {
  background-color: color('digital-black');
  padding-bottom: $spacing-l;
  padding-top: $spacing-l;
}

.footer__body {
  @include make-container-max-widths($footer-max-widths);
  @include make-container;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    flex: 0 0 150px;
    margin-bottom: $spacing-m;
    order: 2;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

//
// Footer Navigation
//

.footer-nav {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__item {
    flex: 0 0 auto;
    padding-left: $spacing-s;
    padding-right: $spacing-s;
    margin-bottom: $spacing-s;
    margin-top: $spacing-s;

    &:not(:last-child){
      border-right: 2px solid color('digital-black');
    }

    @include media-breakpoint-up(md) {
      padding-left: $spacing-m;
      padding-right: $spacing-m;
    }

    .footer-nav--white & {
      color: color('white');

      &:not(:last-child){
        border-right-color: color('white');
      }
    }
  }

  &__link {
    font-weight: normal;

    .footer-nav--white & {
      color: color('white');

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
