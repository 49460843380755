.btn {
  //
  // Base styles
  //
  background: inherit;
  background-image: none;
  border: $btn-border-width solid transparent;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: normal;

  @include btn-size($spacing-m, $spacing-ml, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    text-decoration: none;
  }

  &.focus,
  &:focus {
    box-shadow: $btn-focus-box-shadow;
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: $btn-active-box-shadow;
  }

  @include media-breakpoint-up(sm) {
    display: inline-block;
  }

  // Variants
  &--small {
    @include btn-size($spacing-s, $spacing-m, $small-font-size, $btn-line-height, $btn-border-radius);
  }

  &--primary {
    @include btn-variant(color('digital-blue'), color('digital-blue-dark'), color('ipmi-blue-40'), transparent, transparent);
  }

  &--secondary {
    @include btn-variant(color('gray-01'), color('ipmi-blue-20'), color('gray-05'), transparent, transparent, color('digital-blue'));
  }

  &--quote {
    @include btn-variant(color('digital-orange-logo'), color('digital-orange-dark'), transparent, transparent);
  }

  &-outline--light {
    @include btn-outline-variant(color('white'), color('white'), transparent, color('white'));
  }

  &--block {
    display: block;
    width: 100%;
  }

  // Buttons with icons
  &--with-icon {
    align-items: center;
    display: inline-flex;
  }

  &__icon {
    fill: currentColor;
    height: $spacing-m;
    pointer-events: none;
    vertical-align: text-bottom;
    width: $spacing-m;

    &--left {
      margin-right: $spacing-xs;
    }

    &--right {
      margin-left: $spacing-xs;
    }
  }

  // Language picker
  // Included in header organism
  &--language {
    @include btn-variant(transparent, transparent, transparent, $color: color('digital-blue'), $hover-color: color('digital-blue-dark'));
    @include btn-size($spacing-xs, $spacing-s, $font-size-base, $btn-line-height, 0);

    font-family: $font-family-sans-serif;
    font-weight: 600;

    &:focus, &:active {
      box-shadow: none !important;
    }
  }

  &--btm-language-menu {
    align-items: center;
    background: color('white');
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $spacing-s;
    color: color('digital-black');
    display: flex;
    height: 58px;
    justify-content: space-between;
    line-height: 1.5;
    padding: $spacing-s $spacing-ml $spacing-s $spacing-m;
    width: 100%;
  }

  &--tooltip {
    padding: 0;
  }
}

a.btn {
  display: inline-block;
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
