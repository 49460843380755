//
// Content Blocks
//

$content-block-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 960px,
  xl: 1180px
);

.content-block {
  @include make-container();
  @include make-container-max-widths($content-block-max-widths);
  @include margin-bottom;

  &--seven-five {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > * + * {
      margin-top: $spacing-m;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

      .content-block__col--seven {
      @include media-breakpoint-up(md) {
        @include make-col(7, 12);

        padding-right: 10px;
      }
    }

    .content-block__col--five {
      @include media-breakpoint-up(md) {
        @include make-col(5, 12);

        padding-left: 10px;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4, 12);

        margin-left: percentage($number: 1/12);
      }
    }
  }

  &--with-accordion {
    margin-bottom: $spacing-m;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
