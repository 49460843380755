.share-buttons {
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: $spacing-xxl;

  @include media-breakpoint-up(md) {
    display: inline-flex;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    left: 0;
    position: absolute;
    top: 0;
  }

  &__heading {
    margin-right: $spacing-m;

    @include media-breakpoint-up(lg) {
      color: color('gray-60');
      font-size: $radius-l;
      line-height: 1.166;
      font-weight: $font-weight-bold;
      margin-bottom: $spacing-m;
      margin-right: 0;
    }
  }

  &__btn {
    display: inline-block;
    padding: .625rem;
    font-size: $small-font-size;
    line-height: 14px;
    border-radius: $spacing-xs;
    margin-bottom: $spacing-s;
    margin-right: $spacing-s;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }

    &:hover {
      background-image: none;
      background-color: color('digital-blue-dark');
      
      svg {
        fill: color('white') !important;
      }
    }
  }
}
