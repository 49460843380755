body {
  // Use bootstrap breakpoints in scripts
  // https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
  &::before {
    content: 'xs';
    display: none;
  }

  /* stylelint-disable */
  @include media-breakpoint-up(sm) {
    &::before { content: 'sm'; }
  }

  @include media-breakpoint-up(md) {
    &::before { content: 'md'; }
  }

  @include media-breakpoint-up(lg) {
    &::before { content: 'lg'; }
  }

  @include media-breakpoint-up(xl) {
    &::before { content: 'xl'; }
  }
  /* stylelint-enable */

  &::after {
    display: none;
  }

  @media screen and (orientation: portrait) {
    &::after { content: 'portrait'; }
  }

  @media screen and (orientation: landscape) {
    &::after { content: 'landscape'; }
  }
}
