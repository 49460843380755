.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-s $spacing-m $spacing-s 0;
  margin-bottom: $spacing-m;
  list-style: none;

  &__container {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: 0;
    margin-top: $spacing-l;
  }

  &__item {
    & svg {
      fill: color('digital-blue');
      height: $spacing-ml;
      padding-bottom: 4px;
      width: $spacing-ml;
    }
  }

  &__link {
    @extend %link-underline;

    font-weight: $font-weight-semibold;
  }
}
