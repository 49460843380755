//
// Page headers
//

$page-header-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1180px
);


.page-header {
  @include make-container();
  @include make-container-max-widths($page-header-max-widths);
  @include margin-bottom;

  padding-top: $spacing-xl;

  &--article {
    text-align: center;
  }

  &--basic {
    margin-bottom: $spacing-xxl;
  }

  &--two-col {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > * + * {
      margin-top: $spacing-m;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    .page-header__col--first {
      @include media-breakpoint-up(md) {
        @include make-col(7, 12);

        padding-right: 10px;
      }
    }

    .page-header__col--second {
      @include media-breakpoint-up(md) {
        @include make-col(5, 12);

        padding-left: 10px;
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4, 12);

        margin-left: percentage($number: 1/12);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    margin-bottom: $spacing-m;
  }

  &__subheading {
    @extend .heading--level-2;

    margin-bottom: $headings-margin-bottom;
  }

  &__meta {
    font-family: $font-family-serif;
    font-size: $small-font-size;
    font-style: italic;
    font-weight: 400;
    margin-bottom: $spacing-m;

    &--article {
      font-family: $font-family-sans-serif-2;
      font-style: normal;
    }

    &--date {
      color: color('gray-40');
    }

    @include media-breakpoint-up(md) {
      margin-bottom: $spacing-ml;
    }
  }

  &__image {
    @include img-fluid;

    align-self: flex-start;
    border-radius: $radius-l;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      max-height: 271px;
      width: 481px;
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;

    > * + * {
      padding-top: $spacing-m;
    }
  }

  &__list-item {
    align-items: center;
    display: flex;
  }

  &__checkmark {
    align-self: flex-start;
    flex: 0 0 2rem;
    fill: color('digital-blue');
    height: 2rem;
    margin-right: $spacing-m;
    width: 2rem;
  }

  &__text {
    &--article {
      @include media-breakpoint-up(md) {
        margin: auto;
        max-width: 768px;
        width: 100%;
      }
    }
  }
}

.page-header-full-width-image {
  @include margin-bottom;

  &__wrapper {
    overflow: hidden;
    position: relative;
  }

  &__banner {
    @include media-breakpoint-up(md) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &__card {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    max-height: 300px;

    @include media-breakpoint-up(md) {
      max-height: 350px;
    }
    @include media-breakpoint-up(lg) {
      max-height: 400px;
    }
    @include media-breakpoint-up(xl) {
      max-height: 450px;
    }
  }

  &__image-holder {
    margin-left: auto;
    margin-right: auto;
  }

  &__hero-image {
    min-width: 1px;
  }

  &__background {
    position: absolute;
    top: auto;
    bottom: -3px;
    left: 0;
    height: 200px;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: auto;
    }

    &--accent-blue {
      border-bottom: 2px solid $digital-blue-dark;
      background-color: rgba($digital-blue, 0.85);
    }
  }

  &__foreground {
    position: absolute;
    padding: $spacing-l $spacing-m;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: color('white');
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    p {
      font-weight: normal;
      font-size: $radius-l;
      margin: 0;
    }

    h3 {
      font-size: $spacing-m;
      font-weight: normal;
    }

    @include media-breakpoint-up(md) {
      @include make-container();
      @include make-container-max-widths();

      position: relative;
      min-height: 150px;

      p {
        font-size: $spacing-m;
      }

      h3 {
        font-size: $h3-font-size;
      }
    }

    &--title-only {
      p {
        display: none;
      }

      h3 {
        font-size: $h3-font-size;
      }
    }
  }
}
