//
// Headings
// Use this file in favor of bootstraps _type.scss which has things we don't need
//

h1, h2, h3, h4, h5, h6,
.heading--level-1, .heading--level-2, .heading--level-3,
.heading--level-4, .heading--level-5, .heading--level-6 {
  color: $headings-color;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

h1, .heading--level-1 {
  font-size: $h1-font-size-device;
  letter-spacing: -0.25px;
  line-height: (40 / 36);

  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size;
    line-height: (60 / 48);
  }
}

h2, .heading--level-2 {
  font-size: $h2-font-size-device;
  font-weight: $font-weight-regular;
  line-height: (32 / 26);

  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size;
    line-height: (43 / 36);
  }
}

h3, .heading--level-3 {
  font-size: $h3-font-size;
  line-height: (32 / 28);
}

h4, .heading--level-4 {
  font-size: $h4-font-size;
  line-height: (28 / 22);
}

h5, .heading--level-5 {
  font-size: $h5-font-size;
  line-height: (24 / 18);
}

h6, .heading--level-6 {
  font-size: $h6-font-size;
  font-weight: $font-weight-bold;
  line-height: (24 / 16);
}

.lead {
  font-size: $lead-font-size;
  line-height: (32 / 18);
}

small, .small {
  font-size: $small-font-size;
  line-height: (20 / 14);
}

.micro {
  font-size: $micro-font-size;
  line-height: (14 / 12);
}


//
// Horizontal rules
//

hr {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

// Text Colors
.text {
  &--digital-blue {
    color: color('digital-blue');
  }

  &--white {
    color: color('white');
  }
}
