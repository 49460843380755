.menu-language {
  padding: $spacing-xl $spacing-m $spacing-xl;

  &__item {
    margin-bottom: $spacing-m;
  }

  &__link:not(.active) {
    color: color('digital-black');
  }

  & svg {
    vertical-align: text-bottom;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.menu {
  &__list {
    @include margin-bottom;

    &.collapse, &.collapsing {
      margin-bottom: 0;
    }

    &.show {
      height: calc(100vh - 71px);
      overflow-y: scroll;
    }

    & a {
      background-image: none;
    }

    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }

  &__item {
    border-top: 1px solid rgba(0,0,0,.125);
    cursor: pointer;
    padding: $spacing-m;
    width: 100%;

    @include media-breakpoint-up(sm) {
      padding: $spacing-m;
    }

    &:first-child {
      border-top: none;
    }
  }

  &__text {
    min-height: 40px;
  }

  &__link {
    background-color: transparent;
    border: none;
    color: color('digital-black');
    font-family: $font-family-sans-serif-2;
    font-size: $h5-font-size;
    font-weight: 500;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    @include media-breakpoint-down(sm) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &--mobile {
      color: color('digital-black');
    }

    & svg {
      float: right;
    }

    &.collapsed svg {
      transform: rotate(180deg);
    }
  }

  &__content {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    padding: $spacing-s $spacing-l $spacing-ml;
  }

  &__search {
    padding: $spacing-l $spacing-m $spacing-m;
  }
}

.menu__search + .menu__item {
  border-top: none;
}

.bottom-menu {
  background-color: color('gray-01');
  padding: $spacing-l 0;
  &__language {
    padding: $spacing-m;
  }
  & .menu__link {
    color: color('digital-black');
  }

  @include media-breakpoint-up(md) {
    display: none;
  }

  &__dropmenu {
    background-color: color('white');
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: $spacing-s;
    box-shadow: 0 .125rem $spacing-xs rgba(0, 0, 0, .075);
    font-size: $spacing-m;
    margin: .125rem 0 0;
    padding: $spacing-s 0;
    text-align: left;
    width: calc(100% - 30px);
  }

  .dropdown {
    &.show {
      .language-menu-icn {
        transform: rotate(180deg);
      }
    }
  }
}
