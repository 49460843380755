.logo {
  display: block;
  max-height: 24px;
  max-width: 175px;

  &--white{
    fill: $white;
  }

  @media (min-width: 375px) {
    max-height: 38px;
  }

  @include media-breakpoint-up(md) {
    max-height: 53px;
  }
}
