.featured-posts {
  @include make-container();
  @include make-container-max-widths();

  &__header {
    margin-bottom: $spacing-l;
  }

  &__post {
    margin-bottom: $spacing-l;

    @include make-col-ready();
    @include media-breakpoint-up(lg) {
      @include make-col(4, 12);
    }
  }

  &__image-holder {
    margin-bottom: $spacing-m;

    @include make-col-ready();
    @include media-breakpoint-up(sm) {
      @include make-col(7, 12);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-m;
      @include make-col(12, 12);
    }
  }

  &__row {
    @include make-row();
  }

  &__image {
    border-radius: $spacing-s;
    box-shadow: 0 $spacing-m $spacing-xl 0 rgba(0, 0, 0, 0.3);
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  &__title {
    font-size: $spacing-m;
    margin-bottom: $spacing-s;

    @media (min-width: 370px) and (max-width: 419px){
      font-size: 1.25rem;
    }

    @media (min-width: 420px) and (max-width: 574px) {
      font-size: $spacing-ml;
    }

    @include media-breakpoint-up(md) {
      font-size: $spacing-ml;
    }

    @include media-breakpoint-up(lg) {
      font-size: $spacing-m;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.375rem;
    }
  }

  &__content {
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 50%;
    padding: 0 $spacing-m;
    position: absolute;
    text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5);
    top: 0;
    transform: translate(-50%);
    width: calc(100% - 24px);
  }

  &__category {
    font-size: 0.75rem;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
  }

  &__link {
   @include margin-bottom;

   text-align: center;
   width: 100%;

   @include make-col-ready();
   @include media-breakpoint-up(sm) {
     text-align: left;
   }

   @include media-breakpoint-up(lg) {
     text-align: center;
   }
  }

  &__title-shadow {
    border-radius: $spacing-s;
    bottom: 0;
    box-shadow: inset 0 -65px 15px -5px rgba(88, 89, 91, 0.75);
    height: 100%;
    left: 4px;
    position: absolute;
    width: calc(100% - 8px);
    z-index: -1;
  }
}
