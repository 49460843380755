.accordion {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__title {
    margin-bottom: $spacing-l;
  }

  &__heading {
    align-items: center;
    background-color: transparent;
    border: none;
    border-top: $accordion-border-width solid $accordion-border-color;
    color: color('digital-blue');
    cursor: pointer;
    display: flex;
    font-family: $font-family-sans-serif-2;
    font-size: $font-size-base;
    font-weight: $headings-font-weight;
    padding: $spacing-m;
    text-align: left;
    width: 100%;

    &:hover {
      color: color('digital-blue');
    }

    &:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
    }

    &::before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 1792 1792' fill='%23#{hashless-color(color('digital-blue'))}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10L407 759q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: $spacing-ml;
      content: '';
      flex-shrink: 0;
      height: $spacing-ml;
      margin-right: $spacing-s;
      transform: rotate(0deg);
      transition: transform 0.3s ease 0.05s;
      width: $spacing-ml;
    }

    &.collapsed::before {
      transform: rotate(-90deg);
    }
  }

  &__item:last-child {
    .accordion__heading {
      border-bottom: $accordion-border-width solid $accordion-border-color;
    }
  }

  &__body {
    padding: $spacing-xs $spacing-l $spacing-ml;
  }
}
