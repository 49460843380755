.header {
  &--shadow {
    box-shadow: 0 2px 1px 0 rgba(0,0,0,.1);
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    @include make-container();
    @include make-container-max-widths();
  }

  &--top {
    padding-bottom: $spacing-m;
    padding-top: $spacing-m;
  }

  &--bottom {
    font-family: $font-family-sans-serif-2;
    font-size: $font-size-base * 1.125;
    line-height: $line-height-base;
  }

  &__brand {
    display: inline-block;
    margin-right: $spacing-m;
    white-space: nowrap;
    width: 72px;

    @media (min-width: 375px) {
      width: 110px;
    }

    @include media-breakpoint-up(md) {
      width: 115px;
    }

    @include media-breakpoint-up(lg) {
      width: 152px;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    > * + * {
      margin-left: .75rem;

      @include media-breakpoint-up(lg) {
        margin-left: $spacing-l;
      }
    }

    &--inline {
      align-items: center;
      display: flex;
    }
  }

  &__link {
    background-image: none;
    color: color('digital-black');
    font-weight: $font-weight-semibold;
    opacity: 0.75;
    padding-bottom: 2px;
    padding-top: 0;

    &:hover {
      background-image: linear-gradient(color('digital-black'), color('digital-black'));
      color: color('digital-black');
      opacity: 1;
      text-decoration: none;
    }

    + .nav__link {
      margin-top: $spacing-ml;
    }

    .nav--primary-theme &,
    .menu__content & {
      color: color('digital-blue');
      opacity: 1;

      &:hover {
        background: none;
        color: darken(color('digital-blue'), 12.5);
      }
    }
  }

  &__desktop--hide {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__tablet--hide {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__mobile {
    padding: $spacing-m;

    &--hide {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    & .nav__item {
      min-width: 110px;
    }

    & .nav__item:not(.dropdown) {
      text-align: right;
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.container-sticky {
  padding-top: $spacing-xl;
}

.fixed__top {
  &--mobile {
    @include media-breakpoint-down(md) {
      background-color: color('white');
      position: fixed;
      width: 100%;
      z-index: 3;
    }
  }
}

.scroll__lock {
  &--mobile {
    @include media-breakpoint-down(md) {
      overflow-y: hidden;
    }
  }
}
