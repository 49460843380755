.promo-fw-centered {
  @include make-container();
  @include margin-bottom;

  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  text-align: center;

  &__title {
    color: $digital-blue;
    font-size: 3.5rem;
  }

  &__subtitle {
    color: color('gray-60');
    font-weight: $font-weight-regular;
    margin-bottom: $spacing-ml;
  }

  &__text {
    font-family: $font-family-sans-serif-2;
  }
}

.content-image-block {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &--breakout {
    background-color: color('gray-01');
    border-radius: $radius-l;
    padding: $spacing-l;

    @include media-breakpoint-up(md) {
      padding: $spacing-xxl 8rem $spacing-xxl;
    }
  }

  &--reverse {
    flex-direction: column-reverse;
    -ms-flex-direction: column-reverse; /* IE 10 */

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      -ms-flex-direction: row-reverse; /* IE 10 */
    }
  }

  &__row {
    @include make-row();

    h3 {
      margin-bottom: $spacing-l;
      @extend .heading--level-2;
    }
  }

  &__left {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(6);

      &--reverse {
        @include make-col(6);
      }
    }
  }

  &__right {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    &--reverse {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
  }

  &__image {
    display: block;
    margin-bottom: $spacing-l;
    position: relative;

    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-bottom: 0;
    }

    &::after {
      bottom: 1px;
      box-shadow: 0 0.75rem 2.5rem 0 rgba(0, 0, 0, 0.3);
      content: '';
      height: 4rem;
      left: $spacing-l;
      position: absolute;
      right: $spacing-l;
      z-index: -1;
    }

    &--reverse {
      margin-bottom: 0;
      margin-top: $spacing-l;

      @include media-breakpoint-up(md) {
        float: right;
        margin-top: 0;
      }
    }

    & img {
      border-radius: $radius-l;
      width: 100%;
    }
  }

  & .list {
    padding-left: $spacing-ml;
  }

  & .list__item {
    text-indent: -$spacing-ml;
  }

  & .list-ul {
    margin-left: -1em;
  }

  &__button {
    background-color: color('digital-logo');
    color: color('white');
    margin-bottom: $spacing-ml;
    max-width: 100%;
    width: 320px;
  }

  &__link {
    width: 100%;
  }

  a {
    font-weight: $font-weight-semibold;
  }

  p {
    margin-bottom: $spacing-ml;
  }
}

.tier-3-proposition {
  @include make-row();
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  background-color: color('white');
  padding: $spacing-m $spacing-l;

  @include media-breakpoint-up(md) {
    border-radius: 12px;
    box-shadow: $shadow-default;
    padding: $spacing-xl $spacing-l;
  }

  &__bkgd {
    display: none;

    @include media-breakpoint-up(md) {
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 50%, rgba(248,248,248,1) 100%);
      display: block;
      min-height: 200px;
      position: absolute;
      bottom: 40px;
      width: 100%;
      z-index: -1;
    }
  }

  &__container {
    position: relative;
  }

  &__heading {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-m;
    text-align: center;
  }

  &__icon {
    margin-bottom: $spacing-m;
  }

  &__item {
    margin-bottom: $spacing-l;
    text-align: center;

    @include media-breakpoint-up(md) {
      @include make-col(4);

      padding: 0 $spacing-ml;
    }
  }

  &__link {
    text-align: center;
    width: 100%;
  }

  p {
    font-family: $font-family-sans-serif-2;
  }
}

.plans-learnmore {
  @include margin-bottom;

  background-color: color('ipmi-blue-30');
  padding: $spacing-xxxl $spacing-xxl;
  text-align: center;

  &__heading {
    font-weight: 400;
    margin-bottom: $spacing-l;
    line-height: 1.4;
  }

  &__body {
    @include media-breakpoint-up(md) {
      margin: auto;
      max-width: 580px;
    }
  }

  &__button {
    background-color: color('digital-logo');
    color: color('white');
    margin: auto;
    margin-bottom: $spacing-ml;
    max-width: 100%;

    & .btn--quote {
      @include btn-variant(color('digital-orange-logo'), color('digital-orange-dark'), transparent, transparent);
    }
  }

  &__link {
    width: 100%;
  }

  &--variation-small {
    @include make-container();

    @include media-breakpoint-up(md) {
      max-width: 768px;
    }
  }
  &--variation-medium {
    @include make-container();

    @include media-breakpoint-up(md) {
      @include make-container-max-widths();
    }
  }

  p {
    font-family: $font-family-sans-serif-2;
    margin-bottom: $spacing-l;
  }
}

.item-list-block {
  background-color: color('gray-01');
  border-radius: $radius-l;
  padding: $spacing-m;

  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-l;
  }

  &__body {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &__heading {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: $spacing-l;

    &-heading {
      margin-bottom: $spacing-ml;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);

      margin-bottom: 0;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 20px;

    li {
      font-family: $font-family-sans-serif-2;
      margin-bottom: $spacing-m;
      text-indent: -10px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li::before {
      color: color('digital-blue');
      content: "-";
      display: inline-block;
      width: 10px;
    }
  }

  @include media-breakpoint-up(md) {
    padding: $spacing-l;
  }
}
