::placeholder {
  color: $input-placeholder-color;
  // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
  opacity: 1;
}

/* - Internet Explorer 10–11
   - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: $input-placeholder-color !important;
}

// Select

.select {
  appearance: none;
  background: color('white') url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%230170bc' d='M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10L407 759q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z'/%3E%3C/svg%3E") no-repeat right 1rem center;
  background-size: 20px 20px;
  border: 1px solid color('gray-05');
  color: color('gray-05');
  display: inline-block;
  line-height: 1.5;
  padding: $spacing-s $spacing-l $spacing-s $spacing-m;
  vertical-align: middle;
  width: 100%;
  border-radius: $spacing-s;

  &:focus {
    background-color: color('white');
    outline: 0;
  }

  &:invalid {
    color: color('gray-15');
  }

  option {
    color: color('digital-black');

    &[disabled] {
      display: none;
      color: color('gray-05');
    }
  }
}

/* This is to remove the arrow of select element in IE */
.select::-ms-expand {
  display: none;
}

// Textarea

.textarea {
  border: 1px solid color('gray-05');
  border-radius: $spacing-s;
  resize: none;
  width: 100%;
}

// Input

.input {
  &__text {
    background-color: color('white');
    background-clip: padding-box;
    border: 1px solid color('gray-05');
    border-radius: $spacing-s;
    color: color('digital-black');
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    padding: $spacing-s $spacing-m;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;

    &:focus {
      color: color('digital-black');
      background-color: color('white');
      border-color: color('digital-blue-light');
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
  }

  &__search {
    background-color: color('gray-01');
    border: 1px solid transparent;
  }

  &__search--animated {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    display:block;
    margin: 0 0 0 auto;
    opacity: 0;
    transition: all .5s;
    width: 0;

    &:focus {
      opacity: 1;
      width: 100%;
    }
  }

  &--lg {
    padding: $spacing-m $spacing-ml;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
  }

  &--gutter {
    margin-bottom: $spacing-ml;
  }

  &__inline {
    align-items: center;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &--three {
      @include make-col(3);
    }
  }

  &__feedback {
    align-items: center;
    display: flex;
    margin-top: $spacing-s;

    &--error {
      color: $digital-red;
      font-weight: $font-weight-semibold;
    }
  }

  &__message {
    margin-bottom: 0 !important;
    margin-left: $spacing-s;
  }
}

.input-addon {
  align-items: center;
  display: flex;
  position: relative;

  & .input--icon {
    display: block;
    line-height: .5rem;
    padding: $spacing-s 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.375rem;
    z-index: 2;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      padding: 0;
      top: $spacing-xs;
    }
  }
}

// Validation

.input__validate {
  &--error {
    input,
    select,
    textarea {
      border: 2px solid $digital-red;
      box-shadow: $shadow-default;
    }

    svg {
      align-self: flex-start;
      fill: $digital-red;
      flex: 0 0 1rem;
      height: 1.5rem;
    }
  }

  &--success {
    svg {
      display: block;
      fill: $digital-green;
      position: absolute;
      right: $spacing-s;
      top: 50%;
      transform: translateY(-50%);
    }

    & .input__feedback {
      display: none;
    }
  }
}

// Checkbox

.checkbox {
  &__mark {
    background-color: color('white');
    border: 1px solid color('gray-05');
    border-radius: .25rem;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;

    &::after {
      content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792' fill='%23fff'%3E%3Cpath d='M1550 232q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136L28 662Q0 634 0 594t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/%3E%3C/svg%3E");
      display: block;
      height: 14px;
      left: 3px;
      position: absolute;
      top: 1px;
      width: 14px;
    }

    &--reverse {
      left: inherit;
      right: 0;
    }

    &--mobile-filter {
      margin-top: -10px;
      right: $spacing-ml;
      top: 50%;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__mark {
      background-color: color('digital-blue');
    }
  }

  &__container {
    display: block;
    margin-bottom: $spacing-m;
    position: relative;
    padding-left: 35px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--inline {
      display: inline-block;
      margin-right: $spacing-m;
    }

    &--reverse {
      padding-left: 0;
    }

    &--reverse--modal {
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid color('black');
      }
    }

    &--mobile-filter {
      border-bottom: 1px solid $gray-40;
      padding: 0 $spacing-ml $spacing-ml;
    }
  }
}

.form {
  &__section {
    @include make-container();
    @include make-container-max-widths();

    padding: $spacing-xl $spacing-m;

    &--small {
      max-width: 540px;
    }

    &--medium {
      max-width: 720px;
    }

    &--large {
      max-width: 960px;
    }

    p {
      margin-bottom: $spacing-l;
    }
  }

  &__heading {
    margin-bottom: $spacing-l;
  }

  &__subheading {
    margin-bottom: $spacing-m;
  }

  &__label {
    font-size: $small-font-size;
    font-weight: 500;
  }

  &__link {
    margin-bottom: $spacing-ml;
    text-align: center;
  }

  &__group {
    margin-bottom: $spacing-ml;
  }

  &__message {
    &--error {
      color: $digital-red;
    }
  }
}

.reference-number {
  margin-left: -$spacing-s;
  margin-right: -$spacing-s;

  & input {
    margin: 0 $spacing-s;
    &:nth-child(1) {
      width: 25%;
      @include media-breakpoint-up(md) {
        width: 16.666667%;
      }
    }
  }
}

// International Telephone

.iti {
  display: block !important;

  &__selected-flag {
    height: 40px;
  }
}

// CAPTCHA

.captcha {

  &__image {
    margin-bottom: $spacing-ml;
    width: 60%;
  }
}

// Autocomplete

.autocomplete {
  /* the container must be positioned relative: */
  position: relative;
  display: inline-block;

  &-active {
    /* when navigating through the items using the arrow keys: */
    background-color: color('ipmi-blue-40') !important;
    color: color('white');
  }

  &-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;

    /* position the autocomplete items to be the same width as the container: */
    top: 100%;
    left: 0;

    & div {
      padding: 10px;
      cursor: pointer;
      background-color: color('white');
      border-bottom: 1px solid #d4d4d4;
    }

    & div:hover {
      /* when hovering an item: */
      background-color: #e9e9e9;
    }
  }
}
