.article {
  font-family: $font-family-sans-serif-2;

  &__row {
    @include make-row();
  }

  &__body {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;
    position: relative;

    @include media-breakpoint-up(md) {
      max-width: 991px;
      padding: 0 80px;
    }
  }

  &__posts {
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      max-width: 991px;
      padding: 0 80px;
    }

    &-title {
      font-family: $font-family-sans-serif-2;
      margin-bottom: $spacing-m;
      padding-left: $spacing-l;
      padding-right: $spacing-l;

      @include media-breakpoint-up(sm) {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }
    }
  }

  &__tags {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;

    @include media-breakpoint-up(md) {
      max-width: 991px;
      padding: 0 80px;
    }

    & div {
      align-items: center;
      background-color: $gray-01;
      border-color: transparent;
      border-radius: $spacing-s;
      color: $gray-60;
      display: inline-flex;
      font-size: $micro-font-size;
      font-weight: $font-weight-regular;
      justify-content: center;
      line-height: $line-height-base;
      margin-bottom: $spacing-s;
      padding: $spacing-s $spacing-m;
      text-align: center;
    }
  }

  &__guide-scroll {
    align-items: center;
    color: color('gray-15');
    display: flex;
    font-family: $font-family-sans-serif-2;
    font-size: $small-font-size;
    margin-bottom: $spacing-m;
    flex-direction: column;
  }

  &__sources {
    overflow-wrap: break-word;

    :first-child {
      margin-top: $spacing-m;
    }

    header {
      font-weight: 600;
      margin-bottom: $spacing-m;
      text-transform: uppercase;
    }

    a {
      color: $digital-blue-light;
    }

    p {
      margin-bottom: $spacing-s;
    }
  }

  &__footer {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-xxl;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      max-width: 991px;
      padding: 0 80px;
    }

    &-body {
      border-top: 1px solid $gray-15;
      color: $gray-15;

      p:first-child {
        margin-top: $spacing-m;
      }

      p:last-child {
        margin-bottom: $spacing-l;
      }
    }
  }
}
