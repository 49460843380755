.template {
  &__default {
    padding-bottom: $spacing-xxxl;
    padding-top: $spacing-xl;
  }
}

.container {
  @include media-breakpoint-up(sm) {
    min-height: calc(100vh - 200px);
  }
}
