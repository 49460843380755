//
// Custom lists
//

.list {
  padding-left: 0;
  text-indent: -$spacing-xs;
}

.list-inline {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__row {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  & ul {
    padding-left: 0;
    @include media-breakpoint-up(md) {
      display: flex;
      width: 100%;
    }
  }

  &__heading {
    text-align: left;
  }
}

.list__item {
  list-style: none;

  &--inline {
    padding: $spacing-m $spacing-ml;
    text-indent: -1.8rem;

    & svg {
      margin-right: $spacing-xs;
      vertical-align: top;
    }

    & p {
      display: inline;
    }

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }

  &--product {
    margin-bottom: $spacing-m;
    padding-left: $spacing-l;
    text-indent: -2.8rem;

    & svg {
      height: 25px;
      margin-left: 8px;
      margin-right: 8px;
      width: 25px;
    }
  }
}

.list-section {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__row {
    @include make-row();
    @include media-breakpoint-down(md) {
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }

  &__item {
    @include make-col-ready();

    @include media-breakpoint-down(md) {
      width: 100%;
      min-width: 295px;
    }

    &:first-child {
      padding-left: 10px;
    }

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }

  &__heading {
    margin-bottom: $spacing-l;
    text-align: center;
  }

  &__header {
    @include make-container();
    @include make-container-max-widths();

    margin-bottom: $spacing-l;
    text-align: left;
  }

  &__icon {
    margin-bottom: $spacing-m;
    text-align: center;
  }

  &__link {
    text-align: center;
    width: 100%;
  }

  & hr {
    border-top: 1px solid color('gray-05');
    margin-bottom: $spacing-l;
  }

  & p {
    font-family: $font-family-sans-serif-2;
  }
}

.list-login {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  & a {
    color: inherit;
  }

  &__arrow {
    position: absolute;
    right: $spacing-xs;
    top: 30%;
  }

  &__body {
    border: solid 1px color('ipmi-blue-40');
    padding-bottom: $spacing-s;
    padding-left: $spacing-m;
    padding-right: $spacing-xl;
    padding-top: $spacing-s;
    position: relative;
  }

  &__item {
    @include make-col-ready();

    margin-bottom: $spacing-l;

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }
  }

  &__row {
    @include make-row();
  }
}

.image-list-fw {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__row {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &--reverse {
    flex-direction: row-reverse;
    -ms-flex-direction: row-reverse; /* IE 10 */
  }

  &--flex-item:nth-of-type(1) { @include media-breakpoint-up(md) { order: 3; } }
  &--flex-item:nth-of-type(2) { @include media-breakpoint-up(md) { order: 4; } }

  &__left {
    margin-bottom: $spacing-l;
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      @include make-col(5);
    }

  }

  &__right {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(7);
    }
  }

  &__image {
    display: inline-block;
    float: left;
    margin-bottom: $spacing-s;
    margin-right: $spacing-m;
    max-width: 200px;
    position: relative;

    &::after {
      bottom: 1px;
      box-shadow: 0 0.75rem 2.5rem 0 rgba(0, 0, 0, 0.3);
      content: '';
      height: 4rem;
      left: $spacing-l;
      position: absolute;
      right: $spacing-l;
      z-index: -1;
    }

    &--reverse {
      float: right;
    }

    & img {
      border-radius: $radius-l;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      margin-right: 0;
      max-width: none;
    }
  }

  & .list-ul {
    margin-left: -1em;
  }

  p {
    margin-bottom: $spacing-ml;
  }
}
