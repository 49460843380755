.desktop--hide {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.tablet--hide {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.mobile--hide {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.tablet-only {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.anchor-offset {
  display: block;
  margin-top: -$spacing-xxl;
  padding-top: $spacing-xxl;
  position: relative;
  visibility: hidden;

  @include media-breakpoint-up(md) {
    margin-top: -8rem; // 128px
    padding-top: 8rem;
  }
}

[data-html-toggle="true"] {
  display: none;
}
