@mixin btn-variant(
  $bg,
  $bg-hover,
  $bg-disabled, // color('gray-05')
  $border-color: $bg,
  $border-hover-color: $bg-hover,
  $color: $white,
  $hover-color: $color
) {
  background-color: $bg;
  border-color: $border-color;
  color: $color;

  @include hover {
    background-color: $bg-hover;
    border-color: $border-hover-color;
    color: $hover-color;
  }

  &.disabled,
  &:disabled {
    background-color: $bg-disabled;
  }
}

@mixin btn-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color
) {
  background-color: transparent;
  border-color: $color;
  color: $color;

  @include hover {
    background-color: transparent;
    border-color: $color-hover;
    color: $color-hover;
  }
}

// Button sizes
@mixin btn-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);

  font-size: $font-size;
  line-height: $line-height;
  padding: $padding-y $padding-x;
}
