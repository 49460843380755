.content {
  &__breakout-stats {
    @include make-container();
    @include make-container-max-widths();
    @include margin-bottom;

    font-family: $font-family-sans-serif-2;
    font-size: $h4-font-size;
    text-align: center;

    &::after {
      border-bottom: 2px solid color('gray-15');
      content: ' ';
      display: block;
      margin: auto;
      padding: $spacing-ml;

      @include media-breakpoint-up(md) {
        width: 330px;
      }
    }

    &::before {
      border-top: 2px solid color('gray-15');
      content: ' ';
      display: block;
      margin: auto;
      padding: $spacing-ml;

      @include media-breakpoint-up(md) {
        width: 330px;
      }
    }

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size;
    }
  }
}

.content-list {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__heading {
    margin-bottom: $spacing-ml;
  }

  &__content {
    margin-bottom: $spacing-xl;
  }

  &__left {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(8);
    }
  }

  &__right {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);
    }
  }

  &__row {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }
}

.content-sublist {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  text-align: center;

  &__heading {
    margin-bottom: $spacing-l;
    text-align: center;
  }

  &__content {
    margin-bottom: $spacing-l;
  }

  &__icon {
    margin-bottom: $spacing-m;
    text-align: center;
  }

  &__grid {
    margin-bottom: $spacing-m;

    @include media-breakpoint-up(md) {
      @include make-row();

      margin-left: 20%;
      margin-right: 20%;
    }
  }

  &__item {
    margin-bottom: $spacing-ml;

    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(6);
    }
  }

  &__item-content {
    background-color: color('ipmi-blue-20');
    border-radius: 8px;
    padding: $spacing-ml;
  }

  & hr {
    border-top: 1px solid color('gray-05');
    margin-bottom: $spacing-l;
  }

  & p {
    font-family: $font-family-sans-serif-2;
  }
}

.help-choosing {
  @include make-row();
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__avatar {
    @include make-col(3);

    & img {
      border-radius: 50%;
      float: right;
      height: 80px;
      width: 80px;

      @include media-breakpoint-up(md) {
        height: 150px;
        width: 150px;
      }
    }

    @include media-breakpoint-up(md) {
      @include make-col(2);

      justify-content: center;
    }
  }

  &__content {
    @include make-col(9);

    padding-left: 1rem;

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  p {
    font-family: $font-family-sans-serif-2;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.testimonial {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  &__header {
    margin-bottom: $spacing-l;
    text-align: center;
  }

  &__list {
    display: flex;
    overflow-x: auto;

    &--center {
      @include media-breakpoint-up(md) {
        justify-content: center;
      }
    }
  }

  &__body {
    min-width: 100%;
    padding: 0 $spacing-m;

    @include media-breakpoint-up(md) {
      @include make-col(6);

      min-width: auto;
      padding: 0 $spacing-l;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    font-family: $font-family-sans-serif-2;
  }

  &__author-heading {
    margin-bottom: 0;
  }

  &__author-subheading {
    color: color('digital-green');
    text-transform: uppercase;
  }

  &__avatar {
    margin-right: $spacing-m;
    & img {
      border-radius: 50%;
      height: 80px;
      width: 80px;

      @include media-breakpoint-up(md) {
        height: 80px;
        width: 80px;
      }
    }
  }

  &__content {
    @include media-breakpoint-up(md) {
      padding-left: 85px;
    }
  }

  &__quote {
    border-top: 1px solid #ccc;
    font-family: $font-family-serif;
    font-size: $spacing-ml;
    font-style: italic;
    padding-top: $spacing-ml;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.post {
  @include make-container();
  @include make-container-max-widths();

  font-family: $font-family-sans-serif-2;

  &__row {
    @include make-row();
  }

  &__body {
    margin-bottom: $spacing-xxl;
  }

  &__category {
    color: color('true-black');
    text-transform: uppercase;
  }

  &__date {
    color: color('gray-40');
    margin-right: 5px;
    text-transform: uppercase;
  }

  &__heading {
    color: color('digital-blue');
    margin-bottom: $spacing-ml;
  }

  &__sub {
    font-size: $small-font-size;
    font-weight: 500;
    margin-bottom: $spacing-s;
  }

  &__image {
    border-radius: $radius-l;
    margin-bottom: $spacing-ml;
    overflow: hidden;
  }

  &__link {
    float: right;
  }

  &--col2 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }

  &--col3 {
    @include make-col-ready();
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
  }

  & img {
    width: 100%;
  }
}

.post-filter {
  @include make-container();
  @include make-container-max-widths();

  font-family: $font-family-sans-serif-2;
  margin-bottom: $spacing-xl;

  &__reset-button {
    color: color('digital-blue');

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }

    &:hover {
      color: color('digital-blue-dark');
    }

    &--mobile-filter {
      padding-top: 0;
      text-align: end;
      width: 100%;
    }
  }

  &__mobile-button {
    background-color: color('transparent');
    border: 1px solid color('digital-blue');
    border-radius: 0;
    color: color('digital-blue');
    display: flex;
    justify-content: center;
    width: 100%;

    &:active {
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $spacing-m;
  }

  &__body {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);
    }

    &:last-child {
      [class*="--mobile-filter"]:last-child {
        margin-bottom: 6rem;
      }
    }
  }

  &__heading {
    margin-bottom: 0;
    margin-right: $spacing-m;
  }

  &--desktop-only {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &--mobile-only {
    display: flex;
    justify-content: center;
    margin: $spacing-m 0 $spacing-xl;
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.post-horizontal {
  @include make-container();

  &__body {
    border-top: 1px solid color('gray-40');
    display: inline-block;
    padding: $spacing-xl 0;
  }
}

.post-horizontal-image {
  @include make-container();

  &__body {
    display: flex;
    margin-bottom: $spacing-xl;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  & img {
    border-radius: $radius-l;
    margin-bottom: $spacing-m;
    margin-right: $spacing-m;
    max-width: 100px;
    min-width: 100px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      max-width: 200px;
      min-width: 200px;
    }
  }

  &__link {
    margin-bottom: $spacing-m;
  }
}

.contact {
  background-color: color('gray-01');
  border-radius: $radius-l;
  font-family: $font-family-sans-serif-2;
  max-width: 720px;
  padding: $spacing-ml;

  @include make-container();

  @include media-breakpoint-up(sm) {
    padding: $spacing-ml;
  };

  &__heading {
    margin-bottom: $spacing-m;
  }

  &__list {
    border-bottom: 1px solid color('gray-15');
    padding: $spacing-m 0;

    &:last-child {
      border-bottom: none;
    }

    @include media-breakpoint-up(md) {
      width: 230px;
    }
  }

  label {
    display: block;
    font-size: $small-font-size;
    font-weight: $link-font-weight;
    margin-bottom: 0;
  }

  select {
    margin-bottom: $spacing-xl;
  }

  section {
    margin-bottom: $spacing-xl;

    &:last-child {
      margin-bottom: 0;
    }
  }

  address {
    margin-bottom: 0;
  }
}

.message-cookie-banner {
  background-color: color('gray-60');
  bottom: 0;
  position: fixed;
  width: 100%;

  &__body {
    @include make-row();
    @include make-container();
    @include make-container-max-widths();

    align-items: center;
    color: color('white');
    padding: $spacing-l;
  }

  &__left {
    margin-bottom: $spacing-ml;

    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(9);

      margin-bottom: 0;
    }
  }

  &__right {
    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(3);
    }
  }

  & p {
    margin-bottom: 0;
  }

  & button {
    margin-right: $spacing-s;

    &:last-child {
      margin-right: 0;
    }
  }
}

.filter-flag {
  align-items: center;
  background-color: $gray-01;
  border-color: transparent;
  border-radius: $spacing-s;
  color: $gray-60;
  display: inline-flex;
  font-size: $micro-font-size;
  font-weight: $font-weight-regular;
  justify-content: center;
  line-height: $line-height-base;
  margin-bottom: $spacing-s;
  padding: $spacing-s $spacing-m;
  text-align: center;

  &__icon {
    cursor: pointer;
    fill: currentColor;
    height: $spacing-m;
    margin-left: $spacing-m;
    pointer-events: visible;
    width: $spacing-m;
  }
}

.filter-flag:last-child {
  margin-bottom: $spacing-xl;
}
