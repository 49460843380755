//
// Primary Navigation
//

.primary-nav {
  @include make-container;
  @include make-container-max-widths($primary-nav-max-widths);

  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.primary-nav__list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.primary-nav__item {
  margin: 0 $spacing-s 0 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin: 0 $spacing-ml $spacing-m 0;
  }

  @include media-breakpoint-up(xl) {
    margin-right: $spacing-l;
  }

  &:last-child {
    margin-right: 0;
  }
}

.primary-nav__link {
  background-image: none;
  color: rgba(color('digital-black'), 0.8);
  font-family: $font-family-sans-serif-2;
  line-height: $line-height-base;
  font-weight: 500;
  padding-bottom: 2px;

  &:hover {
    background-image: linear-gradient(color('digital-black'), color('digital-black'));
    background-position: 0 88%;
    background-repeat: repeat-x;
    background-size: 1px 1px;
    color: color('digital-black');
    opacity: 1;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
  }
}

//
// Primary nav dropdown menus
// Showing/hiding done in _dropdowns.scss
//

.dropmenu {
  background-color: $white;
  border: $dropmenu-border-width solid $dropmenu-border-color;
  border-radius: $dropmenu-border-radius;
  box-shadow: $dropmenu-box-shadow;
  margin: $spacing-s 0;
  max-width: 375px;
  padding: $spacing-xs 0 0;
  z-index: $zindex-dropdown;

  &::before {
    background-color: $white;
    border-color: $dropmenu-border-color transparent transparent $dropmenu-border-color;
    border-width: $dropmenu-border-width;
    border-style: solid;
    content: '';
    height: 1rem;
    left: calc(50% - 0.5rem);
    padding: 0;
    position: absolute;
    top: -0.5rem;
    transform: rotate(45deg);
    transform-origin: center;
    width: 1rem;
    z-index: $zindex-dropdown;
  }
}

.dropmenu__item {
  margin: $spacing-m $spacing-ml;

  > * + * {
    margin-top: $spacing-s;
  }
}

.dropmenu__link {
  white-space: nowrap;
}

.dropmenu__description {
  font-size: $small-font-size;
  line-height: (20 / 14);
}


// Subnavigation
// Displays under primary navigation in desktop

.subnav {
  background: $gray-01;
  font-family: $headings-font-family;
  font-size: 1rem;
  letter-spacing: 0.25px;
  line-height: 1.5;
}

.subnav__list {
  @include make-container;
  @include make-container-max-widths($primary-nav-max-widths);

  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  overflow: auto;
  padding-bottom: 0;
  padding-top: 0;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}

.subnav__item {
  &:first-child {
    padding-left: 0;
  }

  &::after {
    border-bottom: 4px solid transparent;
    content: ' ';
    display: block;
    margin: 0 $spacing-ml;
  }

  &:hover {
    &::after {
      border-bottom-color: color('digital-orange-logo');
      margin-left: 0;
      margin-right: $spacing-ml;
    }
  }

  &--active {
    position: relative;

    &::after {
      border-bottom: 4px solid color('digital-orange-logo');
      margin-left: 0;
      margin-right: $spacing-ml;
    }
  }
}

.subnav__link {
  background: none;
  font-family: $font-family-sans-serif-2;
  font-weight: 400;
  color: color('gray-60');
  display: inline-block;
  padding: $spacing-m $spacing-ml $spacing-s 0;

  &:hover {
    background: none;
    color: color('true-black');
  }

  .subnav__item--active & {
    color: color('digital-black');
  }
}
