//
// Alerts
//

$alert-transition: 0.2s ease-out;


.alert {
  margin-bottom: $spacing-xxl;
  opacity: 1;
  padding: $spacing-ml;

  &__overflow {
    height: 100px;
    position: relative;
    overflow: hidden;
    transition: height $alert-transition;
  }

  &__close {
    background-color: transparent;
    border: none;
    color: color('true-black');
    font-size: $h3-font-size;
    position: absolute;
    right: 0;
    top: -10px;

    &:hover {
      color: color('true-black');
    }
  }

  &__heading {
    margin-bottom: $spacing-xs;
  }

  &__body {
    @include make-container;
    @include make-container-max-widths;

    position: relative;
  }

  &__footer {
    background-color: transparent;
    border: none;
    bottom: 0;
    left: 0;
    position: absolute;
    text-align: center;
    transform: scaleY(1);
    transform-origin: bottom;
    transition: transform $alert-transition;
    width: 100%;

    &:focus {
      outline: none;
    }

    .alert--is-expanded & {
      transform: scaleY(0);
    }

    &--error {
      background-image: linear-gradient(to bottom, rgba(255, 227, 226, 0.5) 0%, rgba($bg-error, 0.95) 25%, $bg-error 100%)
    }

    &--success {
      background-image: linear-gradient(to bottom, rgba(231, 242, 229, 0) 0%, rgba($bg-success, 0.95) 25%, $bg-success 100%);
    }

    &--warning {
      background-image: linear-gradient(to bottom, rgba(252, 240, 214, 0) 0%, rgba($bg-warning, 0.95) 25%, $bg-warning 100%);
    }
  }

  &--error {
    background-color: color('error');
  }

  &--success {
    background-color: color('success');
  }

  &--warning {
    background-color: color('warning');
  }

  &--is-hidden {
    opacity: 0;
    transition: opacity $alert-transition;
  }

  & p {
    margin-bottom: 0;
  }
}
