.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1072; // Per Bootstrap rules

  &__dialog {
    margin: $modal-dialog-margin;
    pointer-events: none;
    position: relative;
    width: auto;

    @include media-breakpoint-up(sm) {
      max-width: $modal-md;
      margin: $modal-dialog-margin-y-sm-up auto;
    }

    @include media-breakpoint-up(md) {
      max-width: 750px;
    }

    &--full-width {
      height: 100%;
      margin: 0;
      max-width: 100%;
    }
  }

  &__content {
    background-clip: padding-box;
    background-color: $modal-content-bg;
    border: 0;
    border-radius: $radius-m;
    box-shadow: $shadow-low-30;
    color: $modal-content-color;
    display: flex;
    flex-direction: column;
    outline: 0;
    overflow: hidden;
    pointer-events: auto;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(sm) {
      @include box-shadow($modal-content-box-shadow-sm-up);
    }

    &--full-width {
      border-radius: 0;
      height: 100%;
      overflow: hidden;
      padding: $spacing-m 0 $spacing-xl;

      @include media-breakpoint-up(sm) {
        padding: $spacing-xxl 0;
      }

      @include media-breakpoint-up(lg) {
        padding: $spacing-xxxl 0;
      }
    }

    &--mobile-filter {
      padding: 0;
    }
  }

  &__full-w-modal-content {
    flex: 0 0 100%;
    margin: 0 auto;
    max-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 $spacing-m;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(sm) {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }

    @include media-breakpoint-up(md) {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }

    &--mobile-filter {
      padding: 0;
    }
  }

  &__header {
    align-items: flex-start;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: $spacing-xl $spacing-l 0;
    @include border-top-radius($modal-content-border-radius);

    &--full-width {
      border-bottom: 0;
      margin-bottom: $spacing-ml;
      padding: 0;
    }

    &--mobile-filter {
      align-items: center;
      background: color('digital-blue');
      border-radius: 0;
      color: color('white');
      display: flex;
      min-height: 60px;
      padding: 0 $spacing-ml;
      position: relative;
    }
  }

  &__body {
    flex: 1 1 auto;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $spacing-l $spacing-l $spacing-xl;
    position: relative;

    &--full-width {
      max-height: inherit;
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    align-items: center;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    display: flex;
    justify-content: flex-end;
    padding: $modal-inner-padding;
    @include border-bottom-radius($modal-content-border-radius);

    > :not(:first-child) { margin-left: .25rem; }
    > :not(:last-child) { margin-right: .25rem; }
  }

  &__close {
    background-color: transparent;
    border: none;
    height: 38px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: $spacing-m;
    top: $spacing-m;
    width: 48px;
    z-index: 10;

    &--full-width {
      right: $spacing-s;
      top: $spacing-s;

      @include media-breakpoint-up(sm) {
        right: $spacing-l;
        top: $spacing-l;
      }

      @include media-breakpoint-up(lg) {
        right: $spacing-xl;
        top: $spacing-xl;
      }
    }
  }

  &--fade {
    transition: opacity .15s linear;
    transform: $modal-fade-transform;
  }

  &--fade:not(.show) {
    opacity: 0;
  }

  &.show {
    transform: $modal-show-transform;
  }

  .backdrop {
    background-color: $modal-backdrop-bg;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $zindex-modal-backdrop;

    &.fade { opacity: 0; }
    &.show { opacity: 0.1; }
  }

  &__button {
    &--mobile-filter {
      bottom: $spacing-m;
      height: 55px;
      left: 50%;
      margin-left: -140px;
      max-width: 280px;
      position: fixed;
      width: 100%;
      z-index: 1073;
    }
  }
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
