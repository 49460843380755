.no-ctm #browser-warning,
.no-cookie #browser-warning {
  display: block !important;

  .bad-browser-message { display: block; }
  .no-cookie-message { display: none; }
}

.no-js body {
  // background-color: $white !important;
  // #browser-warning, #iw_viewport-wrapper { display: none !important; }
  #browser-warning { display: none !important; }
}

.no-cookie:not(.no-ctm) #browser-warning {
  .bad-browser-message { display: none; }
  .no-cookie-message { display: block; }
}
