.callout {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom();

  &__heading {
    display: inline-block;
    margin-bottom: $spacing-xl;

    &::after {
      border-bottom: $spacing-xs solid color('digital-orange-logo');
      content: ' ';
      display: block;
      padding-bottom: $spacing-s;
    }
  }

  &__list {
    padding-left: $spacing-m;
    text-indent: -$spacing-s;
  }

  &__item {
    list-style: none;
    margin-bottom: $spacing-m;
    &::before {
      content: '-';
      display: inline-block;
      margin-right: $spacing-xs;
    }
  }

  &__link {
    font-weight: 500;
  }
}
