//
// Global styling
// Applied to areas in components with TinyMCE.
// Allows business users to enter mostly content without needing to know specific class names.
//

[data-mce-area] {
  a {
    color: $link-color;
    cursor: pointer;
    font-weight: $font-weight-semibold;
    padding-bottom: $spacing-xs;
    text-decoration: $link-decoration;

    &:visited {
      @extend %link-underline;

      color: color('digital-purple');
    }

    &:hover {
      @extend %link-underline;

      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }


  // Common unordered/ordered lists

  // Default ordered list
  // TODO: confirm nested styles with business
  ol {
    counter-reset: item;
    list-style-type: none;

    // stylelint-disable-next-line
    > li {
      counter-increment: item;

      &::before {
        content: counter(item) ".";
        padding-right: $spacing-m;
      }
    }
  }

  ul {
    > li {
      &::before {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg preserveAspectRatio='xMinYMin' viewBox='0 0 1792 1792' fill='%23#{hashless-color(color('digital-blue'))}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z'/%3E%3C/svg%3E");
        background-position: top left;
        background-repeat: no-repeat;
        background-size: $spacing-l $spacing-ml;
        content: '';
        padding-right: $spacing-l;
      }
    }
  }

  ul,
  ol {
    list-style: none;
    padding-left: $spacing-xs;

    > li {
      display: table;

      &::before {
        color: color('digital-blue');
        display: table-cell;
      }
    }

    // stylelint-disable-next-line
    > * + * {
      margin-top: $spacing-s
    }

    ul,
    ol {
      margin-top: $spacing-s;
    }
  }

  // stylelint-disable-next-line
  ol ol > li::before {
    content: counter(item, lower-alpha);
  }

  // stylelint-disable-next-line
  ol ol ol > li::before {
    content: counter(item, lower-roman);
  }

  // stylelint-disable-next-line
  ol ol ol ol > li::before {
    content: counter(item, decimal);
  }
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
