.product__plans-Africa-and-IO {
  @include make-container();
  @include make-container-max-widths();
  @include margin-bottom;

  box-shadow: $shadow-default;

  &--top {
    padding: $spacing-ml;
  }

  &--bottom {
    background-color: color('ipmi-blue-30');
    padding: $spacing-ml;
  }

  &--card {
    background-color: color('white');
    border-radius: 8px;
    box-shadow: $shadow-default;
    margin-bottom: $spacing-ml;
    padding: $spacing-ml;
  }

  &--subheading {
    text-transform: uppercase;
    color: color('gray-60');
  }

  &--icons {
    display: flex;
    flex-wrap: wrap;

    span {
      text-align: center;
      padding-right: $spacing-l;

      @include media-breakpoint-up(sm) {
        padding-right: $spacing-l;
      }
    }

    span:first-child {
      padding-left: 0;
    }

    svg {
      @include media-breakpoint-down(xs) {
        height: 2rem !important;
        width: 2rem !important;
      }
    }
  }

  &--buttons {
    a, button {
      @include media-breakpoint-up(md) {
        @include make-col(3);
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a:not(:last-child),
    button:not(:last-child) {
      margin-bottom: $spacing-l;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-right: $spacing-m;
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: flex-end;
    }
  }

  p {
    font-family: $font-family-sans-serif-2;
  }
}
