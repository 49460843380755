$icon-size: (
  s: $spacing-s,
  m: $spacing-m,
  ml: $spacing-ml,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl,
  xxxl: $spacing-xxxl
);

@each $name, $size in $icon-size {
  .icon-#{$name} {
    height: $size !important;
    width: $size !important;
  }
}
