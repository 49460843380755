.search-results {
  &__container {
    @include make-container();
    @include make-container-max-widths();
  }

  &__item {
    margin-bottom: $spacing-l;
  }

  &__heading {
    margin-bottom: 0 !important;

    a, a:hover {
      font-weight: $font-weight-semibold;
    }
  }

  &__link {
    color: color('digital-black');
    display: block;
    font-size: $micro-font-size;
    margin-bottom: $spacing-m;
  }

  &__form {
    margin-bottom: $spacing-m;
  }

  &__pagination--bottom {
    text-align: center;
  }
}

.search-results__spinner {
  display: none;
  text-align: center;
}

.site-search {
  max-width: 500px;

  input[type='search'].tt-input {
    color: $gray-60;
  }

  .tt-menu {
    animation: fadeIn 0.2s;
    background-color: $white;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 0 3px 7px;
    font-family: $font-family-sans-serif-2;
    font-size: 0.8em;
    margin-top: 4px;
    width: 100%;
  }

  .tt-footer {
    margin: 16px 0;
    text-align: center;

    a {
      color: $digital-blue;
      text-decoration: underline;
    }
  }

  .tt-suggestion {
    border-bottom: 1px solid $gray-05;
    color: $digital-black;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 10.5px 14px;
    text-align: left;
    transition: background-color 0.12s;
    white-space: normal;

    &.tt-no-touch {
      background-color: $digital-blue-light;
      color: $white;
      pointer-events: none;
      text-transform: none;
    }

    a {
      color: inherit;
      display: block;
    }
  }

  .tt-dataset:not(:empty) + .tt-dataset:not(:empty) {
    border-top: 2px solid $gray-15;
    padding-top: 8px;
  }

  input[type="search"]::-ms-clear, input[type="search"]::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

.site-search .tt-input, .site-search input[type='search'].tt-hint {
  color: $gray-15;
}

.site-search .tt-suggestion.tt-cursor, .site-search .tt-suggestion:hover {
  background-color: $gray-01;
}

.site-search .tt-suggestion:last-of-type {
  border: 0;
}

@media screen and (min-width: 768px) {
  .site-search .tt-dataset:not(:empty) + .tt-dataset:not(:empty) {
    padding-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .site-search .tt-menu {
    max-width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .sm-btn-holder {
    padding: 0;
    text-align: center;
  }
}

//
// Search CSS
//

#site-search-results-holder {
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: 700px) {
    .csng-pagination-bottom a.csng-synthetic-link,
    .csng-pagination-top a.csng-synthetic-link {
      margin-top: 0;
    }
  }

  @media (min-width: 960px) {
    .csng-nav-tabs li {
      font-size: 16px;
      line-height: 24px;
    }
  }

  a.csng-synthetic-link {
    background-image: none;
    color: #1460aa;
    font-weight: normal;
  }

  a.csng-synthetic-link:hover {
    color: #e35205 !important;
  }

  .csng-spinner {
    animation: a 0.7s infinite linear;
    border-bottom: 5px solid rgba(0, 174, 239, 0.15);
    border-left: 5px solid rgba(0, 174, 239, 0.15);
    border-radius: 100%;
    border-right: 5px solid rgba(0, 174, 239, 0.15);
    border-top: 5px solid rgba(0, 174, 239, 0.8);
    box-sizing: content-box;
    height: 18px;
    position: relative;
    width: 18px;
  }

  @keyframes a {
    0% {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .csng-spinner-small {
    border-bottom: 3px solid rgba(0, 174, 239, 0.15);
    border-left: 3px solid rgba(0, 174, 239, 0.15);
    border-right: 3px solid rgba(0, 174, 239, 0.15);
    border-top: 3px solid rgba(0, 174, 239, 0.8);
    height: 12px;
    width: 12px;
  }

  .csng-spinner-large {
    border-bottom: 7px solid rgba(0, 174, 239, 0.15);
    border-left: 7px solid rgba(0, 174, 239, 0.15);
    border-right: 7px solid rgba(0, 174, 239, 0.15);
    border-top: 7px solid rgba(0, 174, 239, 0.8);
    height: 36px;
    width: 36px;
  }

  .no-cssanimations .csng-spinner {
    border: 0;
    height: 30px;
    width: 30px;
  }

  a.csng-synthetic-link.disabled {
    color: $gray-40;
    cursor: default;
    pointer-events: none;
  }

  .no-cssanimations .csng-spinner-large {
    border: 0;
    height: 78px;
    width: 78px;
  }

  .no-cssanimations .csng-spinner-small {
    border: 0;
    height: 18px;
    width: 18px;
  }

  .search-li .csng-cigna-autocomplete {
    max-width: 210px;
  }

  #csng-search-header-xs .csng-cigna-autocomplete {
    padding: 0.3rem;
  }

  #csng-search-header-xs .csng-cigna-autocomplete:focus {
    box-shadow: none;
    outline: none;
  }

  .csng-nav-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .csng-nav-tabs li {
    color: #9d9d9d;
    cursor: pointer;
    float: left;
    font-family: Gotham SSm A, Gotham SSm B, proximaNovaBold, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
  }

  .csng-nav-tabs li.active {
    background: #fff;
    color: #1460aa;
    cursor: default;
  }

  .csng-pagination-bottom,
  .csng-pagination-top {
    padding: 10px 0;
  }

  .csng-pagination-top {
    border-bottom: 1px solid #ededed;
    margin: 0 1em 30px 0;
  }

  .csng-pagination-bottom {
    border-top: 1px solid #ededed;
    margin: 20px 1em 0 0;
  }

  .csng-pagination-bottom a.csng-synthetic-link,
  .csng-pagination-top a.csng-synthetic-link {
    display: block;
    margin-top: 0.5em;
  }

  .csng-featured-search {
    background-color: #ccedf8;
    margin: 1em 1em 2em 0;
    padding: 1em;
  }

  .csng-espanol-tag {
    background-color: #000;
    color: #fff;
    font-size: 80%;
    font-weight: 400;
    margin-left: 5px;
    padding: 2px;
  }
}

#cigna-navigation-tabs {
  @include media-breakpoint-down(sm) {
    .nav-tabs {
      justify-content: center;

      [data-tab-label].tab-item {
        max-width: calc(50% - 6px);
      }
    }
  }

  select {
    appearance: none;
    background: transparent;
    border: 0;
    height: 40px;
    left: 0;
    padding-left: $spacing-m;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
