.compat-object-fit {
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.hero-basic {
  @include margin-bottom;

  position: relative;

  & img {
    height: 600px;
    object-fit: cover;
    width: 100%;
  }

  &--rounded {
    border-radius: $radius-l;
  }

  &--variation-small {
    @include make-container();

    & img, & .compat-object-fit {
      height: 385px;
    }

    @include media-breakpoint-up(md) {
      max-width: 768px;
    }
  }

  &--variation-medium {
    @include make-container();

    & img, & .compat-object-fit {
      height: 440px;
    }

    @include media-breakpoint-up(md) {
      @include make-container-max-widths();
    }
  }
}

.hero-icon-xl {
  @include margin-bottom;

  display: flex;
  flex-wrap: wrap;
  background-color: color('gray-01');
  padding: $spacing-xl $spacing-m $spacing-xl;

  &__left {
    text-align: right;
    @include make-col-ready();
    @include make-col(3);
  }

  &__right {
    @include make-col-ready();
    @include make-col(9);
  }

  &__icon {
    justify-content: center;
    align-items: center;
    border-radius: 44px;
    display: inline-flex;

    & svg {
      fill: color('white');
      height: 72px;
      width: 72px;

      @include media-breakpoint-up(md) {
        height: 6.5rem;
        width: 6.5rem;
      }
    }

    @include media-breakpoint-up(md) {
      border-radius: 60px;
      height: 120px;
      margin-right: $spacing-m;
      width: 120px;
    }
  }

  h3 {
    margin-bottom: $spacing-m;
  }

  @include media-breakpoint-up(md) {
    padding: 6rem 8rem $spacing-xl;
  }
}

.hero-breakout {
  @include margin-bottom;

  position: relative;

  &__body {
    color: color('white');
    left: 50%;
    padding: 0 $spacing-m;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding: 0 20%;
    }
  }

  &__heading {
    margin-bottom: $spacing-ml;
  }

  & img {
    height: 600px;
    object-fit: cover;
    width: 100%;
  }

  p {
    margin-bottom: $spacing-ml;
  }

  &--variation-small {
    @include make-container();

    padding: 0;

    & img {
      border-radius: $radius-l;
      height: 385px;
    }

    @include media-breakpoint-up(md) {
      max-width: 768px;
    }
  }

  &--variation-medium {
    @include make-container();

    padding: 0;

    & img {
      border-radius: $radius-l;
      height: 500px;
    }

    @include media-breakpoint-up(md) {
      @include make-container-max-widths();
    }
  }
}

.hero-promo {
  position: relative;

  & img {
    // object-fit: cover;
    width: 100%;
  }

  &__promo-block {
    background-color: color('gray-01');
    border-radius: $radius-l;
    margin-bottom: $spacing-xl;
    padding: $spacing-m;

    @include make-container();
    @include make-container-max-widths();

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      margin-bottom: $spacing-xxl;
      padding: $spacing-l;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-l;
  }

  &__body {
    @include media-breakpoint-up(md) {
      @include make-row();
    }
  }

  &__heading {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: $spacing-l;

    &-heading {
      margin-bottom: $spacing-ml;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      @include make-col-ready();
      @include make-col(4);

      margin-bottom: 0;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 20px;

    li {
      font-family: $font-family-sans-serif-2;
      margin-bottom: $spacing-m;
      text-indent: -10px;
    }

    li:last-child {
      margin-bottom: 0;
    }

    li::before {
      color: color('digital-blue');
      content: "-";
      display: inline-block;
      width: 10px;
    }
  }
}
