@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(sm) {
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }
}
