//
// Dropdown menus
//

.dropdown-menu {
  display: none;

  &.show {
    display: block;
  }
}
